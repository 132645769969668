import api from "../Configuration/ApiConfig";

const UserService = {
  GetUserList: () => {
    const request = api.get('User/GetUsersList')
    return request.then((response) => {
      return response.data
    })
  },
  GetUser: () => {
    const request = api.get("User/GetUserDetail");
    return request.then((response) => {
      return response.data;
    });
  },
  UpdateUserProfile: (userData) => {
    const request = api.put("User/UpdateUser", userData);
    return request.then((response) => {
      return response.data;
    });
  },
  changePassword: (userCredentials) => {
    const request = api.put("User/ChangePassword", userCredentials);
    return request.then((response) => {
      return response.data;
    });
  },
  AddUser: (addUserData) => {
    const request = api.post("User/AddUser", addUserData);
    return request.then((response) => {
      return response.data;
    });
  },
  DeleteUser: (id) => {
    const request = api.delete(`User/DeleteUser?id=${id}`);
    return request.then((response) => {
      return response.data;
    });
  },
  GetUserById: (id) => {
    const request = api.get(`User/GetUserById/${id}`);
    return request.then((response) => {
      return response.data;
    });
  },
}
export default UserService;