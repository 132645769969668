import { useState, useEffect } from "react";
import LoginService from "../../API/LoginService";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import { notification } from "antd";
import Lodder from "../Loader";

const LoginForm = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [submit, setSubmit] = useState(true);
    const navigate = useNavigate();

    const [emailValidation, setEmailValidation] = useState({
        isValid: true,
        message: "",
    });
    const [passwordValidation, setPasswordValidation] = useState({
        isValid: true,
        message: "",
    });

    useEffect(() => {
        let accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            navigate("/home");
        } else if (Cookies.get("email") && Cookies.get("password")) {
            setEmail(Cookies.get("email"));
            setPassword(Cookies.get("password"));
        } else {
            setEmail("");
            setPassword("");
        }
    }, []);

    const IsValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const ValidateEmail = (value) => {
        if (!value.trim()) {
            setEmailValidation({ isValid: false, message: "Email is required" });
        } else if (value.length > 100) {
            setEmailValidation({
                isValid: false,
                message: "Email can not be more than 50 characters",
            });
            return false;
        } else if (!IsValidEmail(value)) {
            setEmailValidation({
                isValid: false,
                message: "Please enter a valid email",
            });
            return false;
        } else {
            setEmailValidation({
                isValid: true,
                message: "",
            });
            return true;
        }
    };

    const ValidatePassword = (value) => {
        if (!value.trim()) {
            setPasswordValidation({
                isValid: false,
                message: "Password is required",
            });
            return false;
        } else {
            setPasswordValidation({
                isValid: true,
                message: "",
            });
            return true;
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        ValidateEmail(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        ValidatePassword(event.target.value);
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleFormSubmit = (event) => {
        setSubmit(false);
        event.preventDefault();
        ValidateEmail(email);
        ValidatePassword(password);
        if (
            ValidateEmail(email) &&
            ValidatePassword(password)
        ) {
            var loginCredentials = {
                email: email,
                password: password,
            };
            LoginService.Login(loginCredentials)
                .then((response) => {
                    if (response.status) {
                        localStorage.setItem("userRole", response.resultData.userDetail.roleId);
                        localStorage.setItem("accessToken", response.resultData.token.token);
                        if (isChecked) {
                            Cookies.set("email", email, { expires: 14 });
                            Cookies.set("password", password, { expires: 14 });
                        }
                        setEmail("");
                        setPassword("");
                        navigate("/home", { replace: true });
                        setSubmit(true);
                    }
                })
                .catch((error) => {
                    // notification.error({
                    //     message: "Login attempt failed due to incorrect credential",
                    // });
                    setPasswordValidation({
                        isValid: false,
                        message: error.response.data.Message,
                    });
                    setSubmit(true);
                });
        } else {
            setSubmit(true);

        }
    };
    return (
        <div>
            <div className="vh-100 d-flex justify-content-center align-items-center flex-column login">
                <div className="bg-white rounded-4 shadow box-width">
                    <div className="row">
                        <div className="col-lg-6 p-0 col-sm-12">
                            <div className="background-img">
                                <div className="background-color">
                                    <h3 className="title">GiniLytics<span className="sub-title d-block" style={{marginTop: "14px", fontSize: "20px"}}>Wholesale ERP</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="card border-0 py-lg-5 py-3" style={{marginBottom: "0"}}>
                                <div className="card-body p-5 ">
                                    <h3 className="card-heading text-center mb-0">Welcome</h3>
                                    <h5 className="card-title text-center mb-5" style={{float: "none"}}>Log In to GiniLytics Wholesale ERP</h5>
                                    <form className="" onSubmit={handleFormSubmit}>
                                        <div className="mb-3 mt-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="email" id="email" name="email" className="form-control"
                                                value={email}
                                                onChange={handleEmailChange} />
                                            {!emailValidation.isValid && (
                                                <span style={{ color: "red" }}>
                                                    {emailValidation.message}
                                                </span>
                                            )}

                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input type="password" id="password" name="password" className="form-control"
                                                value={password}
                                                onChange={handlePasswordChange} />
                                            {!passwordValidation.isValid && (
                                                <span style={{ color: "red" }}>
                                                    {passwordValidation.message}
                                                </span>
                                            )}

                                        </div>
                                        <div className="mb-4 form-check d-flex justify-content-between">
                                            <div >
                                                <input type="checkbox" id="remember-me" formcontrolname="rememberMe" className="form-check-input ng-untouched ng-pristine ng-valid"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="remember-me" className="form-check-label remember-text">Remember Me</label>
                                            </div>
                                            <Link className="text-decoration-none forgot-text" to="/forgotpassword">
                                                Forgot Password?
                                            </Link>
                                        </div>
                                        <div className="text-end mt-4">
                                            <button type="submit" className="btn btn-primary w-100"
                                            >LOG IN</button></div>

                                    </form>
                                </div>
                            </div>
                            <Lodder show={!submit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LoginForm;