import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Lodder from "../Loader";
import { notification } from "antd";
import LoginService from "../../API/LoginService";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const ResetPassword = () => {

    const userId = useParams().userId;
    const [submit, setSubmit] = useState(true);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const [newPasswordValidation, setNewPasswordValidation] = useState({
        isValid: true,
        message: "",
    });
    const [confirmPasswordValidation, setConfirmPasswordValidation] = useState({
        isValid: true,
        message: "",
    });

    const ValidateNewPassword = (value) => {
        if (!value.trim()) {
            setNewPasswordValidation({
                isValid: false,
                message: "Password is required",
            });
            return false;
        } else if (value.length < 6) {
            setNewPasswordValidation({
                isValid: false,
                message: "New password must be at least 6 characters",
            });
            return false;
        } else {
            setNewPasswordValidation({
                isValid: true,
                message: "",
            });
            return true;
        }
    };

    const ValidateConfirmPassword = (value) => {
        if (!value.trim()) {
            setConfirmPasswordValidation({
                isValid: false,
                message: "Confirm password is required",
            });
            return false;
        } else if (value !== newPassword) {
            setConfirmPasswordValidation({
                isValid: false,
                message: "New password and confirm password must be same",
            });
            return false;
        } else {
            setConfirmPasswordValidation({
                isValid: true,
                message: "",
            });
            return true;
        }
    };

    const handleNewPasswordChange = (event) => {
        if (event.target.value === confirmPassword) {
            setConfirmPasswordValidation({
                isValid: true,
                message: "",
            });
        }else{
            setConfirmPasswordValidation({
                isValid: false,
                message: "New password and confirm password must be same",
            });
        }
        setNewPassword(event.target.value);
        ValidateNewPassword(event.target.value);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        ValidateConfirmPassword(event.target.value);
    };

    const handleFormSubmit = (event) => {
        setSubmit(false);
        event.preventDefault();
        ValidateNewPassword(newPassword);
        ValidateConfirmPassword(confirmPassword);
        if (
            ValidateNewPassword(newPassword) &&
            ValidateConfirmPassword(confirmPassword)
        ) {
            var resetPassword = {
                userId: userId,
                Newpassword: newPassword,
            };
            LoginService.ResetPassword(resetPassword)
                .then((response) => {
                    if (response.status) {
                        notification.success({
                            message: "Password changed successfully",
                            icon: <CheckCircleOutlined style={{ color: 'var(--primary)' }} />
                        });
                        navigate('/')
                        setSubmit(true);
                    }
                })
                .catch((error) => {
                    notification.error({
                        message: error.response.data.Message,
                        icon: <CloseCircleOutlined style={{ color: 'var(--primary)' }} />
                    });
                    setSubmit(true);
                });
        } else {
            setSubmit(true);
        }
    };

    return (
        <div>
            <div className="vh-100 d-flex justify-content-center align-items-center flex-column login">
                <div className="bg-white rounded-4 shadow box-width">
                    <div className="row">
                        <div className="col-lg-6 p-0 col-sm-12">
                            <div className="background-img">
                                <div className="background-color">
                                    <h3 className="title">GiniLytics<span className="sub-title d-block">Wholesale ERP</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="card border-0 py-lg-5 py-3" style={{marginBottom: "0"}}>
                                <div className="card-body p-5 ">
                                    <h5 className="sub-title text-center mb-5">Reset Password</h5>
                                    <form className="" onSubmit={handleFormSubmit}>
                                        <div className="mb-3 mt-3">
                                            <label htmlFor="password" className="form-label">New Password</label>
                                            <input type="password" id="newpassword" name="newpassword" className="form-control"
                                                value={newPassword}
                                                onChange={handleNewPasswordChange}
                                            />
                                            {!newPasswordValidation.isValid && (
                                                <span style={{ color: "red" }}>
                                                    {newPasswordValidation.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="password" className="form-label">Confirm Password</label>
                                            <input type="password" id="confirmpassword" name="confirmpassword" className="form-control"
                                                value={confirmPassword}
                                                onChange={handleConfirmPasswordChange}
                                            />
                                            {!confirmPasswordValidation.isValid && (
                                                <span style={{ color: "red" }}>
                                                    {confirmPasswordValidation.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="text-end mt-4">
                                            <button type="submit" className="btn btn-primary w-100"
                                            >SUBMIT</button></div>
                                        <div className="text-center mt-4">
                                            <Link className="text-decoration-none forgot-text" to="/">
                                                Back to Log In
                                            </Link></div>
                                    </form>
                                </div>
                            </div>
                            <Lodder show={!submit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ResetPassword;