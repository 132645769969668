import { useState, useEffect } from "react";
import Lodder from "../Loader";
import {
    LockOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import UserService from "../../API/UserService";
import { useNavigate, useParams } from "react-router-dom";
import LoginService from "../../API/LoginService";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const SignUp = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState({
        firstName: '',
        lastName: '',
        roleName: '',
        email: ''
    });

    useEffect(() => {
        UserService
            .GetUserById(id)
            .then((response) => {
                if (response.status) {
                    setSelectedUser(response.resultData);
                } else if (response.resultData === null) {
                    notification.info({
                        message: "This account does not exist or has been deleted",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const [show, setShow] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [newPasswordValidation, setNewPasswordValidation] = useState({
        isValid: false,
        message: "",
    });
    const [confirmPasswordValidation, setConfirmPasswordValidation] = useState({
        isValid: true,
        message: "",
    });

    const ValidateNewPassword = (value) => {
        if (!value.trim()) {
            setNewPasswordValidation({
                isValid: false,
                message: "New password is required",
            });
        } else if (value.length < 6) {
            setNewPasswordValidation({
                isValid: false,
                message: "New password must be at least 6 characters",
            });
        } else {
            setNewPasswordValidation({
                isValid: true,
                message: "",
            });
        }
    };

    const ValidateConfirmPassword = (value) => {
        if (!value.trim()) {
            setConfirmPasswordValidation({
                isValid: false,
                message: "Confirm password is required",
            });
        } else if (value !== newPassword) {
            setConfirmPasswordValidation({
                isValid: false,
                message: "New password and confirm password must be same",
            });
        } else {
            setConfirmPasswordValidation({
                isValid: true,
                message: "",
            });
        }
    };

    const handleNewPasswordChange = (event) => {
        if (event.target.value === confirmPassword) {
            setConfirmPasswordValidation({
                isValid: true,
                message: "",
            });
        } else {
            setConfirmPasswordValidation({
                isValid: false,
                message: "New password and confirm password must be same",
            });
        }
        setNewPassword(event.target.value);
        ValidateNewPassword(event.target.value);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        ValidateConfirmPassword(event.target.value);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setShow(true);
        ValidateNewPassword(newPassword);
        ValidateConfirmPassword(confirmPassword);
        if (
            newPasswordValidation.isValid &&
            confirmPasswordValidation.isValid
        ) {
            var userData = {
                userId: id,
                NewPassword: newPassword,
            };
            LoginService
                .ResetPassword(userData)
                .then((response) => {
                    if (response.status) {
                        notification.success({
                            message: "Account created successfully",
                            icon: <CheckCircleOutlined style={{ color: 'var(--primary)' }} />
                        });
                        setShow(false);
                        localStorage.removeItem("accessToken");
                        navigate("/");
                    }
                })
                .catch((error) => {
                    notification.error({
                        message: error.response.data.Message,
                        icon: <CloseCircleOutlined style={{ color: 'var(--primary)' }} />
                    });
                    setShow(false);
                });
        } else {
            setShow(false);
        }
    };

    return (
        <div>
            <div className="vh-100 d-flex justify-content-center align-items-center flex-column login">
                <div className="bg-white rounded-4 shadow box-width-signup">
                    <div className="row">
                        {/* <div className="col-lg-6 p-0 col-sm-12">
                            <div className="background-img">
                                <div className="background-color">
                                    <h3 className="title">Wholesale ERP<span className="sub-title d-block">FOODS AND WINES</span></h3>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-12 col-sm-12">
                            <div className="card border-0 py-lg-5 py-3" style={{ marginBottom: "0" }}>
                                <div className="card-body p-5 ">
                                    <h3 className="card-heading text-center mb-0">Welcome</h3>
                                    <h5 className="card-title text-center mb-5" style={{ float: "none" }}>Please create a password to finish setting up your account.</h5>
                                    <form className="" onSubmit={handleFormSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label">First Name</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    value={selectedUser?.firstName}
                                                />

                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label">Last Name</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    value={selectedUser?.lastName}
                                                />

                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label">Email</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    value={selectedUser?.email}
                                                />

                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label">Role</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    value={selectedUser?.roleName}
                                                />

                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label" htmlFor="password">
                                                    New Password<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <div style={{ position: "relative" }}>
                                                    <input
                                                        className="form-control"
                                                        type={showNewPassword ? "text" : "password"}
                                                        value={newPassword}
                                                        onChange={handleNewPasswordChange}
                                                        style={{ padding: "0.375rem 2.75rem" }}
                                                    />
                                                    {showNewPassword ? (
                                                        <EyeOutlined
                                                            onClick={() => {
                                                                setShowNewPassword(!showNewPassword);
                                                            }}
                                                            style={{
                                                                position: "absolute",
                                                                top: "15px",
                                                                right: "11px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <EyeInvisibleOutlined
                                                            onClick={() => {
                                                                setShowNewPassword(!showNewPassword);
                                                            }}
                                                            style={{
                                                                position: "absolute",
                                                                top: "15px",
                                                                right: "11px",
                                                            }}
                                                        />
                                                    )}
                                                    <LockOutlined
                                                        className="fa-solid fa-lock"
                                                        style={{
                                                            position: "absolute",
                                                            top: "15px",
                                                            left: "17px",
                                                        }}
                                                    ></LockOutlined>
                                                </div>
                                                {!newPasswordValidation.isValid && (
                                                    <span style={{ color: "red" }}>
                                                        {newPasswordValidation.message}
                                                    </span>
                                                )}

                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label" htmlFor="password">
                                                    Confirm Password<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <div style={{ position: "relative" }}>
                                                    <input
                                                        className="form-control"
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        value={confirmPassword}
                                                        onChange={handleConfirmPasswordChange}
                                                        style={{ padding: "0.375rem 2.75rem" }}
                                                    />
                                                    {showConfirmPassword ? (
                                                        <EyeOutlined
                                                            onClick={() => {
                                                                setShowConfirmPassword(!showConfirmPassword);
                                                            }}
                                                            style={{
                                                                position: "absolute",
                                                                top: "15px",
                                                                right: "11px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <EyeInvisibleOutlined
                                                            onClick={() => {
                                                                setShowConfirmPassword(!showConfirmPassword);
                                                            }}
                                                            style={{
                                                                position: "absolute",
                                                                top: "15px",
                                                                right: "11px",
                                                            }}
                                                        />
                                                    )}
                                                    <LockOutlined
                                                        className="fa-solid fa-lock"
                                                        style={{
                                                            position: "absolute",
                                                            top: "15px",
                                                            left: "17px",
                                                        }}
                                                    ></LockOutlined>
                                                </div>
                                                {!confirmPasswordValidation.isValid && (
                                                    <span style={{ color: "red" }}>
                                                        {confirmPasswordValidation.message}
                                                    </span>
                                                )}

                                            </div>
                                        </div>


                                        <div className="text-center mt-4">
                                            <button type="submit" className="btn btn-primary w-100"
                                            >CREATE MY ACCOUNT</button></div>

                                    </form>
                                </div>
                            </div>
                            <Lodder show={show} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SignUp;