import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { notification } from "antd";
import Lodder from "../Loader";
import LoginService from "../../API/LoginService";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [submit, setSubmit] = useState(true);
    const navigate = useNavigate();

    const [emailValidation, setEmailValidation] = useState({
        isValid: true,
        message: "",
    });

    const IsValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const ValidateEmail = (value) => {
        if (!value.trim()) {
            setEmailValidation({ isValid: false, message: "Email is required" });
        } else if (value.length > 100) {
            setEmailValidation({
                isValid: false,
                message: "Email can not be more than 50 characters",
            });
            return false;
        } else if (!IsValidEmail(value)) {
            setEmailValidation({
                isValid: false,
                message: "Please enter a valid email",
            });
            return false;
        } else {
            setEmailValidation({
                isValid: true,
                message: "",
            });
            return true;
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        ValidateEmail(event.target.value);
    };

    const handleFormSubmit = (event) => {
        setSubmit(false);
        event.preventDefault();
        ValidateEmail(email);
        if (
            ValidateEmail(email)
        ) {
            LoginService.ForgotPassword(email)
                .then((response) => {
                    if (response.status) {
                        notification.success({
                            message: 'Email sent successfully',
                            description: 'Please check your mailbox.',
                            icon: <CheckCircleOutlined style={{ color: 'var(--primary)' }} />
                          });
                        navigate("/", { replace: true });
                        setSubmit(true);
                    }
                })
                .catch((error) => {
                    notification.error({
                        message: error.response.data.Message,
                        icon: <CloseCircleOutlined style={{ color: 'var(--primary)' }} />
                    });
                    setSubmit(true);
                });
        } else {
            setSubmit(true);

        }
    };

    return (
        <div>
            <div className="vh-100 d-flex justify-content-center align-items-center flex-column login">
                <div className="bg-white rounded-4 shadow box-width">
                    <div className="row">
                        <div className="col-lg-6 p-0 col-sm-12">
                            <div className="background-img">
                                <div className="background-color">
                                    <h3 className="title">GiniLytics<span className="sub-title d-block">Wholesale ERP</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="card border-0 py-lg-5 py-3" style={{marginBottom: "0"}}>
                                <div className="card-body p-5 ">
                                    <h1 className="sub-title text-center mb-5">Forgot Password</h1>
                                    <form className="" onSubmit={handleFormSubmit}>
                                        <div className="mb-3 mt-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="email" id="email" name="email" className="form-control" 
                                            value={email}
                                            onChange={handleEmailChange}
                                            />
                                            {!emailValidation.isValid && (
                                                <span style={{ color: "red" }}>
                                                    {emailValidation.message}
                                                </span>
                                            )}

                                        </div>
                                        <div className="text-end mt-4">
                                            <button type="submit" className="btn btn-primary w-100"
                                            >SUBMIT</button></div>
                                        <div className="text-center mt-4">
                                        <Link className="text-decoration-none forgot-text" to="/">
                                               Back to Log In
                                            </Link></div>

                                    </form>
                                </div>
                            </div>
                            <Lodder show={!submit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ForgotPassword;